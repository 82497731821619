

function Tours() {
  this.resizeListener = function() {
    if (this.visible) {
      self.showTourItem();
    }
  };
}

export default Tours;

Tours.prototype.start = function(tourName) {
  var self = this;

  self.visible = true;

  this.tours = {
    noPhone: [
      {
        selector: '.noPhoneStep1',
        text: "Please verify your primary phone number."
      }
    ],
    reminderList: [
      {
        selector: '.reminderListTourStep1',
        text: "We added reminders for all work holidays."
      },
      {
        selector: '.reminderListTourStep2',
        text: "Click the plus sign to add a new reminder."
      }
    ],
    reminderUpdate: [
      {
        selector: '.reminderUpdateTourStep1',
        text: "Your reminder text will be spoken back to you as a phone call or sent as a text message."
      },
    ],
    afterPurchase: [
      {
        selector: '#thankYouTour',
        text: "Login with your email and password"
      }
    ]
  };

  if (this.tours.hasOwnProperty(tourName)) {
    this.tour = this.tours[tourName];
    this.item = 0;

    this.showTourItem();
  }
  window.removeEventListener("resize", this.resizeListener);
  window.addEventListener("resize", this.resizeListener);
};

Tours.prototype.destroyTippies = function() {
  document.querySelectorAll('*').forEach(node => {
    if (node._tippy) {
      node._tippy.destroy();
    }
  });
}

Tours.prototype.showTourItem = function() {
  var self = this;

  Vue.nextTick(function() {
    self.destroyTippies();
    $('.z-overlay').remove();

    var item = self.tour[self.item];
    var rect = $(item.selector)[0].getBoundingClientRect();
    /*var width = $(document).width();
    var height = $(document).height();*/
    var width = $(window).width();
    var height = $(window).height();
    var el = $('<div class="z-highlight"/>');
    var margin = 20;
    var css = {
      left: rect.left - margin + 'px',
      right: (width - rect.right) - margin + 'px',
      top: rect.top - margin + 'px',
      bottom: (height - rect.bottom) - margin + 'px',
    };

    var newCSS = {
      'clip-path': 'inset(0px 0px ' + ((height - rect.top) + margin) + 'px 0px)'
    };
    newCSS['-webkit-clip-path'] = newCSS['clip-path'];
    $('body').append($('<div id="overlaytop" class="z-overlay"/>'));
    $('#overlaytop').css(newCSS);

    newCSS = {
      'clip-path': 'inset(' + (rect.top - margin) + 'px ' + ((width - rect.left) + margin) + 'px ' + ((height - rect.bottom) - margin) + 'px 0px)'
    };
    newCSS['-webkit-clip-path'] = newCSS['clip-path'];
    $('body').append($('<div id="overlayleft" class="z-overlay"/>'));
    $('#overlayleft').css(newCSS);

    newCSS = {
      'clip-path': 'inset(' + (rect.top - margin) + 'px 0px ' + ((height - rect.bottom) - margin) + 'px ' + (rect.right + margin) + 'px)'
    };
    newCSS['-webkit-clip-path'] = newCSS['clip-path'];
    $('body').append($('<div id="overlayright" class="z-overlay"/>'));
    $('#overlayright').css(newCSS);

    newCSS = {
      'clip-path': 'inset(' + (rect.bottom + margin) + 'px 0px 0px 0px)'
    };
    newCSS['-webkit-clip-path'] = newCSS['clip-path'];
    $('body').append($('<div id="overlaybottom" class="z-overlay"/>'));
    $('#overlaybottom').css(newCSS);

    var buttonsHTML = '';

    if ((self.tour.length === 1) || (self.item === (self.tour.length - 1))) {
      buttonsHTML = '<div class="column"><a href="#" class="tourskip button is-accent">Close</a></div>';
    }
    else {
      buttonsHTML = '<div class="column has-text-right"><a href="#" class="tourskip button is-accent">Skip Tips</a></div><div class="column is-narrow"><a href="#" class="tournext button is-accent">Next</a></div>';
    }

    var html = '<div class="columns"><div class="column">CONTENT</div></div><div class="columns">' + buttonsHTML + '</div>';
    html = html.replace('CONTENT', item.text);
    /*tippy.setDefaults({
      appendTo: function() {
        return document.querySelector('#app');
      }
    });*/
    var t = tippy(item.selector, {
      arrow: true,
      distance: margin * 2,
      hideOnClick: false,
      placement: 'bottom-start',
      animateFill: false,
      animation: false,
      interactive: true,
      content: html,
      onHide: function() {
        return false;
      }
    });

    var button = document.querySelector(item.selector);
    var instance = button._tippy;

    instance.show();

    $('.tourskip').off('click').on('click', function() {
      self.stop();
    });
    $('.tournext').off('click').on('click', function() {
      if (self.item >= (self.tours.length - 1)) {
        self.stop();
      }
      else {
        self.item += 1;
        self.showTourItem();
      }
    });
  });
}

Tours.prototype.stop = function() {
  var self = this;

  self.visible = false;
  self.destroyTippies();
  $('.z-overlay').remove();
  $('.z-highlight').remove();
}


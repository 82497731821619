var jquery = require("/js/lib/jquery");
import 'babel-polyfill';
import ServiceBotHandlerIndex from './serviceBotHandlerIndex';

window.$ = window.jQuery = jquery;

import cookie from './cookie';
import config from './clientServerConfig.json';
import wsCall, { wsCallAsync } from './wsCall';
import Tours from './tours';
import appData from './appData';

function App() {
  var self = this;

}

App.prototype.init = async function() {
  var self = this;

  if (window.location.href.indexOf('#thank-you', window.location.length - '#thank-you'.length) !== -1) {
    self.showAfterPurchase = true;
  }

  // var referral = window.hasOwnProperty("Rewardful") ? Rewardful.referral : '';
  appData.init({
    watch: {
    },
    computed: {
    },
    methods: {
    },
    mounted: self.initServiceBot
  });

  window.addEventListener('Rewardful.tracked', () => {
    // Rewardful has finished loading
    // `Rewardful.referral` and `Rewardful.affiliate` are now available.
    //document.getElementById('pwframe_pw_5d7137ca6adb4').src = appData.vm.paywhirlPricingLink();
  });

  self.setMediaQueryData();
  window.addEventListener("resize", function() { self.setMediaQueryData() });

  $('.getStarted').on('click', function() {
    document.querySelector('.sectionPlans').scrollIntoView({
      behavior: 'smooth'
    });
  });

  $('#audioPlaybackButton').on('click', function() {
    var audio = document.getElementById("audioPlayback");
    audio.play();
  });

  if (self.showAfterPurchase) {
    self.tours.start('afterPurchase');
  }
};

App.prototype.initServiceBot = function() {
  window.servicebotSettings = {
    "servicebot_id": config.env[process.env.APPENV].servicebotId,
    "service": "Foneself",
    "options": {
        "forceCard": true,
        "card": {
            "hideAddress": true
        },
        "behavior": {
            "signup": {
                "termsAndConditions": {
                    "required": true,
                    "link": "https://www.foneself.com/terms.html",
                    "errorMessage": "You must accept the Terms and Conditions",
                    "text": "Accept Terms and Conditions"
                }
            },
            "pricing": {
                "showPriceAsMonth": true,
                "showPriceAsMonthDiscountBadge": true
            }        }
    },
    "type": "pricing",
    "integration": "html",
    "handleResponse": ServiceBotHandlerIndex.handler
  };
  (function () { var s = document.createElement('script'); s.src = 'https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js'; s.async = true; s.type = 'text/javascript'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();

  var serviceBotModified = false;

  function callbackElementInserted(records) {
    records.forEach(function (record) {
      var list = record.addedNodes;
      var i = list.length - 1;
      
    for ( ; i > -1; i-- ) {
      if (findBilled(list[i])) {
        setTimeout(function() {
          if (!serviceBotModified) {
            serviceBotModified = true;
            $('#servicebot-subscription-portal .__tier-1 > div.__body > div > div > div > span').append('<p><div class="plan-item-servicebot">One phone</div><div class="plan-item-servicebot">100 credits</div><div class="plan-item-servicebot">Phone app (beta)</div></p>');
            $('#servicebot-subscription-portal .__tier-2 > div.__body > div > div > div > span').append('<p><div class="plan-item-servicebot">Up to three phones</div><div class="plan-item-servicebot">200 credits</div><div class="plan-item-servicebot">Phone app (beta)</div></p>');
          }
        }, 0);

      }
    }
    });
  }

  function findBilled(element) {
    let children = element.childNodes;

    for (let i = 0; i < children.length; i++) {
      if (children[i].classList && children[i].classList.contains('__billed') || findBilled(children[i])) {
        return true;
      }
    }

    return false;
  }

  var observer = new MutationObserver(callbackElementInserted);

  var targetNode = document.body;

  observer.observe(targetNode, { childList: true, subtree: true });  
}

App.prototype.setMediaQueryData = function() {
  appData.vm.screenSM = window.matchMedia("(min-width: 576px)").matches;
  appData.vm.screenMD = window.matchMedia("(min-width: 768px)").matches;
  appData.vm.screenLG = window.matchMedia("(min-width: 992px)").matches;
  appData.vm.screenXL = window.matchMedia("(min-width: 1200px)").matches;
};

var app = new App();

app.init();


export default app;


import Dialog from './dialog';

/* global Dialog */
var DialogResponse = {};

export default DialogResponse;

DialogResponse.show = function(jqXHR, defaultParams) {
  var params = defaultParams;

  if ((jqXHR.status < 500) && jqXHR.responseJSON && (jqXHR.responseJSON.errorType !== 'unknown') && (jqXHR.responseJSON.errorType !== 'logic')) {
    switch (jqXHR.responseJSON.errorType) {
      case 'validation':
        params.title = 'Invalid';
        if (jqXHR.responseJSON.userMessage) {
          params.message = jqXHR.responseJSON.userMessage;
        }
        break;
      case 'creditCard':
        params.title = 'Credit Card Error';
        if (jqXHR.responseJSON.userMessage) {
          params.message = jqXHR.responseJSON.userMessage;
        }
        break;
      case 'authentication':
        params.title = 'Login';
        params.message = 'Invalid username or password.';
        if (jqXHR.responseJSON.userMessage) {
          params.message = jqXHR.responseJSON.userMessage;
        }
        break;
      case 'authenticationExpired':
        params.title = 'Login';
        params.message = 'Your login has expired.';
        if (jqXHR.responseJSON.userMessage) {
          params.message = jqXHR.responseJSON.userMessage;
        }
        params.afterClose = function() {
          Cookie.remove('jwtAccess');
          Cookie.remove('jwtRefresh');
          window.location.href = '/login';
        };
        break;
      case 'authorization':
        params.title = 'Authorization';
        params.message = 'You are not authorized to perform this action.';
        if (jqXHR.responseJSON.userMessage) {
          params.message = jqXHR.responseJSON.userMessage;
        }
        break;
    }
  }
  Dialog.show(params);
};

import cookie from './cookie';
import config from './clientServerConfig.json';
import dayjs from 'dayjs';
var moment = require('moment-timezone');

function AppData() {
}

AppData.prototype.init = function(options) {
  var self = this;

  var vue = {
    el: '#app',
    data: {
      activePage: 'login',
      betaTestSignupName: null,
      betaTestSignupPhone: null,
      betaTestSignupEmail: null,
      betaTestSignupPassword: null,
      dialog: {
        info: {
          button2: null,
          message: "",
          show: false,
          title: ""
        },
        delete: {
          requireInput: false,
          show: false,
          text: ""
        },
      },
      loginURL: '',
      loginEmail: null,
      loginPassword: null,
      pageloaderVisible: false,
      pageloaderText: 'Loading',
      phoneLimit: null,
      phoneUpdateId: null,
      phoneUpdatePhone: null,
      phoneUpdateType: null,
      phoneUpdatePrimary: false,
      phoneUpdateCarrier: null,
      phoneUpdatePhoneName: null,
      phoneUpdateVerified: false,
      phoneUpdateCode: null,
      phoneUpdateStep: 1,
      phoneUpdateLastStep: 2,
      phoneList: null,
      resetPassword: null,
      reminderUpdateAppointment: false,
      reminderUpdateNotifyBefore: "60",
      reminderUpdateOccurrence: "once",
      reminderUpdateDate: null,
      reminderUpdateHoliday: 'christmas',
      reminderUpdateId: null,
      reminderUpdateIsHoliday: false,
      reminderUpdateNotifyBefore: "60",
      reminderUpdateMethod: "text",
      reminderUpdatePhoneId: null,
      reminderUpdateTime: null,
      reminderUpdateTzClient: moment.tz.guess(),
      reminderUpdateText: null,
      reminderListOnce: null,
      reminderListRecurring: null,
      reminderListHoliday: null,
      reminderListHistory: null,
      screenSM: window.matchMedia("(min-width: 576px)").matches,
      screenMD: window.matchMedia("(min-width: 768px)").matches,
      screenLG: window.matchMedia("(min-width: 992px)").matches,
      screenXL: window.matchMedia("(min-width: 1200px)").matches,
      serverConfig: config.env[process.env.APPENV],
      serviceBotHmac: null,
      setupEmail: null,
      setupEmailConfirmShow: false,
      setupPassword: null,
      setupPasswordShow: false,
      setupPhone: null,
      setupPhoneShow: false,
      timeZones: moment.tz.zonesForCountry('US')
    },
    watch: options.watch,
    computed: options.computed,
    methods: options.methods,
    mounted: options.mounted
  };

  vue.data.saved = {
    email: null,
    dataRates: false,
    primaryPhoneVerified: false,
    primaryPhoneId: null,
    privileges: {
      account: {
      },
      phone: {
      },
      limit: {
      }
    },
    reminderAdvancedOpen: false,
    reminderListOpen: {
      once: true,
      recurring: true,
      holiday: true,
      history: true
    },
    terms: false,
    tours: {
      reminderList: false,
      reminderUpdate: false
    }
  };
  vue.data.save = function() {
    window.localStorage.setItem('savedVueData', JSON.stringify(self.vm.saved));
  };

  var savedVueData = window.localStorage.getItem('savedVueData');

  if (savedVueData) {
    vue.data.saved = $.extend(true, {}, vue.data.saved, JSON.parse(savedVueData));
  }

  vue.data.loginEmail = cookie.get('loginEmail');

  // Record domain URL for Paywhirl widgets
  vue.data.loginURL = window.location.protocol + window.location.host;

  var dt = dayjs();

  dt = dt.add(1, 'day').set('hour', 8).set('minute', 0);

  vue.data.reminderUpdateDate = dt.format('YYYY-MM-DD');
  vue.data.reminderUpdateTime = dt.format('HH:mm');

  this.vm = new Vue(vue);
};

var appData = new AppData();

export default appData;




var Cookie = {};
export default Cookie;

import config from './clientServerConfig.json';

function getCookieDomain() {
  var cookieDomain = config.env[process.env.APPENV].cookieDomain;
  if (cookieDomain && cookieDomain !== '') {
    cookieDomain = ";domain=" + cookieDomain;
  }
  else {
    cookieDomain = '';
  }

  return cookieDomain;
}

Cookie.remove = function(name) {
  // https://stackoverflow.com/a/2138471/5086812
  var cookieDelete = name+'=; Max-Age=-99999999' + getCookieDomain() + ";path=/";
  document.cookie = cookieDelete;
};

Cookie.get = function(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      var n = c.substring(name.length, c.length);
      // Remove the "j:" that ExpressJS
      if (/^j:/.test(n)) {
        n = n.substring(2);
      }
      // Server cleared the cookie
      if (n === '')
      {
        n = null;
      }
      return n;
    }
  }
  return null;
};


Cookie.set = function(params) {
  var milliseconds;

  if (params.name && (params.value !== undefined) && (params.value !== null)) {
    if (params.minutes) {
      milliseconds = params.minutes*60*1000;
    }
    else if (params.hours) {
      milliseconds = params.hours*60*60*1000;
    }
    else if (params.days) {
      milliseconds = params.days*24*60*60*1000;
    }
    else {
      console.error('Cookie params.days, params.hours or params.minutes not specified');
      return;
    }

    var expires = "";
    if (milliseconds) {
        var date = new Date();
        date.setTime(date.getTime() + milliseconds);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = params.name + "=" + (params.value || "")  + expires + "; path=/";
  }
  else {
    console.error('Cookie params.name or params.value not specified');
  }
};

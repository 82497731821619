var jquery = require("/js/lib/jquery");
window.$ = window.jQuery = jquery;

import wsCall, { wsCallAsync } from './wsCall';
import appData from './appData';
var moment = require('moment-timezone');

var ServiceBotHandlerIndex    = {};
export default ServiceBotHandlerIndex;

ServiceBotHandlerIndex.handler = async function(payload) {
  if (payload.event == 'pre_subscribe') {
    return await ServiceBotHandlerIndex.preSubscribe(payload);
  }
  if (payload.event == 'create_subscription') {
    return await ServiceBotHandlerIndex.createSubscription(payload);
  }
  if (payload.event == 'change_plan') {
    location.reload();
  }
  if (payload.event == 'resubscribe') {
    location.reload();
  }
  if (payload.event == 'cancel_subscription') {
    location.reload();
  }
};

ServiceBotHandlerIndex.preSubscribeCheckPhoneNumberUS = async function() {
  var p = new Promise(async function(resolve, reject) {
    $('#dialogConfirmUS').addClass('is-active');

    $('.dialogConfirmUSCancel').on('click', function() {
      $('#dialogConfirmUS').removeClass('is-active');
      resolve(false);
    });
    $('.dialogConfirmUSConfirm').on('click', function() {
      $('#dialogConfirmUS').removeClass('is-active');
      resolve(true);
    });
  });

  return p;
};

ServiceBotHandlerIndex.preSubscribe = async function(payload) {
  return new Promise(async function(resolve, reject) {

    try {
      let isUS = await ServiceBotHandlerIndex.preSubscribeCheckPhoneNumberUS();
      if (!isUS) {
        reject({
          message: 'Foneself subscription canceled.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
      }

      appData.vm.pageloaderText = 'Loading';
      appData.vm.pageloaderVisible = true;

      let result = await wsCallAsync({ 
      url: "/account/exists",
      data: {
        email: payload.response.email
      }});

      if (result.data.exists) {
        reject({
          message: 'User already exists. Please log in to change your subscription.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
      }
      else {
        resolve();
      }
    }
    catch (err) {
      reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
    }
  });
};

ServiceBotHandlerIndex.createSubscription = async function(payload) {
  return new Promise(async function(resolve, reject) {
    appData.vm.pageloaderText = 'Loading';
    appData.vm.pageloaderVisible = true;
    try {
      let result = await wsCallAsync({ 
      url: "/account/create",
      data: {
        payload: payload,
        tzClient: moment.tz.guess()
      }});

      if (result.error) {
        reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
      }
      else {
        window.location = '/setup?resetPasswordId=' + result.data.resetPasswordId + '&email=' + payload.response.customer.email;
        resolve();
      }
    }
    catch (err) {
      reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
    }
  });
};
